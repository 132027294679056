/* eslint-disable no-alert */
<template>
  <div class="page1">
    <!--主体内容-->
    <div class="content">
      <template>
        <div class="container" />
      </template>
      <!--搜索条件区域-->
      <div
        class="searchWrapper"
        @keydown.enter.prevent="
          pageNum = 1;
          searchData();
        "
      >
        <el-form :inline="true" label-position="right" class="demo-form-inline">
          <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
          <div class="col_box">
            <div class="col_left">
              <el-form-item :label="$t('searchModule.Event_Name')">
                <el-input v-model.trim="couponActivityName" filterable placeholder="输入活动名称">
                </el-input>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Activity_status')">
                <el-select v-model.trim="status" clearable>
                  <!-- <el-option label="未审核" :value="0"/> -->
                  <el-option label="全部" value="" />
                  <el-option label="未开始" :value="1" />
                  <el-option label="进行中" :value="2" />
                  <el-option label="已结束" :value="3" />
                  <el-option label="已下架" :value="4" />
                </el-select>
              </el-form-item>
            </div>
            <div class="col_right">
              <AuthorityComponent
                ComponentName="el-button"
                :permission="['button.query']"
                type="primary"
                icon="el-icon-search"
                @click="
                  pageNum = 1;
                  searchData();
                "
                :loading="loading"
                >{{ $t('button.search') }}</AuthorityComponent
              >
            </div>
          </div>
          <div class="col_box_boder"></div>
          <!-- 如果没有下面按钮就不需要 -->
          <div class="col_box h44">
            <div class="col_left">
              <AuthorityComponent
                ComponentName="el-button"
                :permission="['button.add']"
                type="primary"
                icon="el-icon-plus"
                @click="$router.push('/activityManagementadd')"
                >添加</AuthorityComponent
              >
            </div>
            <div class="col_right mbd4">
              <!-- 导出或下载按钮 -->
            </div>
          </div>
        </el-form>
      </div>
      <!--列表区域-->
      <div class="tableWrapper bgFFF paddingB10">
        <el-table v-loading="loading" :data="tableData" style="width: 100%">
          <el-table-column type="index" :label="$t('list.index')" align="center" width="48"></el-table-column>
          <el-table-column :label="$t('searchModule.Event_Name')" width="" show-overflow-tooltip>
            <template slot-scope="scope">
              <span>{{ scope.row.couponActivityName }}</span>
            </template>
          </el-table-column>
          <el-table-column label="活动时间" width="115px" show-overflow-tooltip>
            <template slot-scope="scope">
              <span>{{ scope.row.startTime }}至{{ scope.row.endTime }}</span>
            </template>
          </el-table-column>
          <el-table-column label="优惠券发行量" show-overflow-tooltip>
            <template slot-scope="scope">
              <span>{{ scope.row.publishAmount }}</span>
            </template>
          </el-table-column>
          <el-table-column label="领取量" width="" show-overflow-tooltip>
            <template slot-scope="scope">
              <span>{{ scope.row.receiveAmount | receiveAmountFilter }}</span>
            </template>
          </el-table-column>
          <el-table-column label="使用量" width="" show-overflow-tooltip>
            <template slot-scope="scope">
              <span>{{ scope.row.usedAmount | usedAmountFilter }}</span>
            </template>
          </el-table-column>
          <el-table-column :label="$t('searchModule.Activity_status')" width="" show-overflow-tooltip>
            <template slot-scope="scope">
              <span>{{ scope.row.status | status }}</span>
            </template>
          </el-table-column>
          <el-table-column :label="$t('searchModule.Audit_status')" width="" show-overflow-tooltip>
            <template slot-scope="scope">
              <span>{{ scope.row.auditStatus | auditStatus }}</span>
            </template>
          </el-table-column>
          <el-table-column label="活动链接" width="140px" show-overflow-tooltip>
            <template slot-scope="scope">
              <span v-clipboard:copy="scope.row.url" v-clipboard:success="onCopy">
                <p>{{ scope.row.url }}</p>
              </span>
            </template>
          </el-table-column>
          <el-table-column
            label="操作"
            width="70"
            v-if="
              !(
                !buttons.view &&
                !buttons.edit &&
                !buttons.down &&
                !buttons.conf &&
                !buttons.examine
              )
            "
          >
            <template slot-scope="scope">
              <el-dropdown @command="dropdownClick($event, scope.$index, scope.row)">
                <el-button type="text" size="small" style="padding: 0">
                  操作
                  <i class="el-icon-arrow-down" />
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <AuthorityComponent
                    ComponentName="el-dropdown-item"
                    :permission="['button.view']"
                    command="a"
                    >查看</AuthorityComponent
                  >
                  <AuthorityComponent
                    ComponentName="el-dropdown-item"
                    :permission="['button.edit']"
                    command="b"
                    v-if="scope.row.auditStatus == 0"
                    >修改</AuthorityComponent
                  >
                  <AuthorityComponent
                    ComponentName="el-dropdown-item"
                    :permission="['button.examine']"
                    command="e"
                    v-if="scope.row.auditStatus == 0"
                    >审核</AuthorityComponent
                  >
                  <AuthorityComponent
                    ComponentName="el-dropdown-item"
                    :permission="['button.down']"
                    command="f"
                    v-if="
                      (scope.row.status == 1 || scope.row.status == 2) && scope.row.auditStatus == 1
                    "
                    >下架</AuthorityComponent
                  >
                  <AuthorityComponent
                    ComponentName="el-dropdown-item"
                    :permission="['button.conf']"
                    command="c"
                    v-if="scope.row.auditStatus == 0"
                    >模板配置</AuthorityComponent
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
        <!--分页器-->
        <div class="pagerWrapper">
          <div class="block">
            <el-pagination
              v-if="total > 0"
              @current-change="handleCurrentChange"
              :current-page="pageNum"
              :page-size="pageSize"
              layout="total, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "activityManagement",
  filters: {
    receiveAmountFilter(val) {
      if (!val) return 0;
      return val;
    },
    usedAmountFilter(val) {
      if (!val) return 0;
      return val;
    },
    publishNum(data) {
      return (
        data.amount.split(",").reduce((pre, next) => {
          return pre + next * 1;
        }, 0) * data.publishAmount
      );
    },
    couponNum(val) {
      let obj = {
        1: "单张券",
      };
      return obj[val] ? obj[val] : "多张券";
    },
    status(val) {
      let obj = {
        0: "未审核",
        1: "未开始",
        2: "进行中",
        3: "已结束",
        4: "已下架",
      };
      return obj[val] ? obj[val] : "";
    },
    auditStatus(val) {
      let obj = {
        0: "未审核",
        1: "已审核",
      };
      return obj[val] ? obj[val] : "";
    },
  },
  data() {
    return {
      message: "111111111111",
      see: false,
      edit: false,
      model: false,
      delect: false,
      examine: false,
      shelf: false,
      index: 0,
      total: 0,
      pageSize: 15,
      pageNum: 1,
      loading: false,
      couponActivityName: "",
      status: "",
      cityId: "40",
      k: "a559ea80-1c73-4117-ad83-42a6d5857794",
      detailFlag: false,
      areaList: [],
      parkList: [],
      tenantList: [],
      rsTypeList: {},
      tableData: [],
      formInline: {
        status: "",
        rsType: "",
        parkId: "",
        tenantId: "",
      },
      buttons: this.$route.meta.authority.button,
    };
  },
  methods: {
    onCopy() {
      this.$alert("复制成功", this.$t('pop_up.Tips'), {
        confirmButtonText: this.$t('pop_up.Determine'),
      });
    },
    dataProcessing(val) {
      this.message = val;
    },
    dropdownClick(com, index, row) {
      if (com == "a") {
        this.$router.push({
          path: "/activityManagementsee",
          query: row,
        });
      }
      if (com == "b") {
        this.$router.push({
          path: "/activityManagementadd",
          query: row,
        });
      }
      if (com == "c") {
        if (row.haveTemplate == false) {
          this.$router.push({
            path: "/configuration",
            query: row,
          });
        } else {
          this.$router.push({
            path: "/Yconfiguration",
            query: row,
          });
        }
      }
      if (com == "d") {
        this.close(row);
      }
      if (com == "e") {
        this.unclose(row);
      }
      if (com == "f") {
        this.unload(row);
      }
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.searchData();
    },
    showLog(data) {
      if (data && data.length === 2) {
        // let startTime = dateFormat(data[0], 'yyyy-MM-dd HH:mm:ss');
        // let endTime = dateFormat(data[1], 'yyyy-MM-dd HH:mm:ss');
        // this.formInline.startTime = startTime;
        // this.formInline.endTime = endTime;
      } else {
        this.formInline.startTime = "";
        this.formInline.endTime = "";
      }
    },
    changeDetail(type) {
      if ((this.index === 0 && type === "pre") || (this.index === 14 && type === "next")) {
        this.$alert("没有更多数据了", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
      } else {
        type === "pre" ? this.index-- : this.index++;
        let opt = {
          url: "/sym_analysis/api/v1/parking/queryParkRecordDetail",
          data: {
            parkRecordId: this.tableData[this.index].parkRecordId,
          },
          success: (res) => {
            this.infoDetail = res.data.ParkRecordVO;
          },
        };
        // getData(opt);
      }
    },
    showDetail(index, obj) {
      this.$router.push({
        path: "/parkDetail",
        query: obj,
      });
    },
    // 获取区域
    getArea() {
      let opt = {
        url: "/gplus/city/queryAreaListByUserAndCity",
        data: {},
        success: (res) => {
          this.areaList = res.data.CityVO;
        },
      };
      // getData(opt);
    },
    // 获取停车场
    getPark() {
      this.parkList = [];
      let opt = {
        url: "/gplus/park/queryParkDropDownList",
        data: {
          rsType: this.formInline.rsType,
          cityId: this.formInline.cityId,
        },
        success: (res) => {
          this.parkList = res.data.ParkVO;
          this.formInline.parkId = "";
        },
      };
      // getData(opt);
    },
    // 获取商户
    getTenant() {
      let opt = {
        url: "/gplus/bdTenant/getAllTenants",
        data: {},
        success: (res) => {
          this.tenantList = res.data.allTenants;
        },
      };
      // getData(opt);
    },
    // 车场类型
    getRsType() {
      let opt = {
        url: "/gplus/park/queryAllRsParkType",
        data: {},
        success: (res) => {
          this.rsTypeList = JSON.parse(res.data.RsParkType);
        },
      };
      // getData(opt);
    },
    unclose(row) {
      let opt = {
        method: "post",
        url: "/acb/2.0/couponActivity/examine",
        data: {
          couponActivityId: row.couponActivityId,
        },
        success: (res) => {
          this.$alert("操作成功", this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
          this.searchData();
        },
      };
      this.$confirm("审核后的活动将正式启用，确认通过审核？", this.$t('pop_up.Tips'), {
        confirmButtonText: this.$t('pop_up.Determine'),
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          if (!row.url) {
            this.$alert("请先进行模版配置", this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
            return;
          }
          if (new Date().getTime() > new Date(row.startTime).getTime()) {
            this.$alert("当前时间不得大于活动开始时间", this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
            return;
          }
          this.$request(opt);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    close({ couponActivityId }) {
      let opt = {
        url: "/gplus/couponActivity/delete",
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        data: {
          id: couponActivityId,
        },
        success: (res) => {
          this.$alert("操作成功", this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
          this.searchData();
        },
        fail: (res) => {
          this.loading = false;
          this.$alert(res.msg, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        },
      };
      this.$confirm("确认删除？", this.$t('pop_up.Tips'), {
        confirmButtonText: this.$t('pop_up.Determine'),
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          // getData(opt);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    unload({ couponActivityId }) {
      let opt = {
        method: "post",
        url: "/acb/2.0/couponActivity/unload",
        data: {
          couponActivityId,
        },
        success: (res) => {
          this.$alert("操作成功", this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
          this.searchData();
        },
        fail: (res) => {
          this.loading = false;
          this.$alert(res.msg, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        },
      };
      this.$confirm("下架会关闭活动，但已领取的优惠券有效，确认下架？", this.$t('pop_up.Tips'), {
        confirmButtonText: this.$t('pop_up.Determine'),
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$request(opt);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    // 搜索
    searchData(pageNum) {
      this.tableData = [];
      this.loading = true;
      const opt = {
        method: "get",
        url: "/acb/2.0/couponActivity/list",
        data: {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          status: this.status,
        },
        success: (res) => {
          this.tableData = res.value.list;
          this.total = parseInt(res.value.total);
          this.loading = false;
        },
      };
      if (this.couponActivityName) {
        opt.data.couponActivityName = this.couponActivityName;
      }
      this.$request(opt);
    },
  },
  activated() {
    this.searchData();
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus"></style>
